<template>
  <div class="findDeviceBGView">
    <div class="findDeviceInPut">
      <el-input
          ref="searchInput"
          readonly
          @focus="showSearchView"
          placeholder="请输入内容"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>

    <div class="findDeviceMap">
      <map-view></map-view>
    </div>

    <div class="findDeviceList" id="findDeviceList">
      <ul class="findDeviceUl">
        <li class="findDeviceLi" v-for="(item) in deviceList">
          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              资产名称
            </div>
            <div class="content">
              {{ item.name }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              保管人
            </div>
            <div class="content">
              {{ item.userName }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              资产类型
            </div>
            <div class="content">
              {{ item.type == 1 ? '特殊' : '普通' }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              资产分类
            </div>
            <div class="content">
              {{ item.assetClassName }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              科室
            </div>
            <div class="content">
              {{ item.orgName }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              {{$VUEAPPDEVICECODE()}}
            </div>
            <div class="content">
              {{ item.blueDeviceNumber }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              资产类型
            </div>
            <div class="content">
              {{ item.userName }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              资产位置
            </div>
            <div class="content">
              {{ item.userName }}
            </div>
          </h-stack>

          <h-stack class="findDeviceLiContent" justify-content="flex-start" align-items="flex-start">
            <div class="title">
              资产状态
            </div>
            <div class="content">
              {{ item.userName }}
            </div>
          </h-stack>

          <div class="line"></div>
        </li>
      </ul>
    </div>

    <h-stack class="findDevicePage">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>

    <el-drawer
        :modal="false"
        size="100%"
        :visible.sync="showSearch"
        direction="btt"
    >
      <search-view
          v-show="true"
          ref="searchView"
          @updateSelect="updateSelect"
          @sure="sure"
          :search-list="searchList"
      ></search-view>
    </el-drawer>


  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import SearchView from '@/views/findDevice/searchView/searchView'
import { Loading } from 'element-ui'
import MapView from '@/components/mapView/mapView'

export default {
  name: 'findDevice',
  components: { MapView, SearchView, HStack },
  data() {
    return {
      index:0,
      loadingView: null,
      loadingStatus: true,
      page: 1,
      pageSize: 50,
      totalCount: 0,
      showSearch: false,
      deviceList: [{name:'123'},{name:'123'},{name:'123'}, {name:'123'}, {name:'123'}, {name:'123'}],
      searchList: [{
        loading: true,
        title: '资产分类',
        selectList: [],
        dataList: [],
        type: 'asset'
      }, {
        loading: true,
        title: '品牌型号',
        dataList: [],
        selectList: [],
        type: 'model'
      }]
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'login') {
      next(true)
      return
    }
    let leave = () => {
      if (this.showSearch) {
        this.showSearch = false
        next(false)
        if (typeof this.$refs.searchView == 'undefined') {
          return
        }
        this.$refs.searchView.resetOldStore()
        return
      }
      next(true)
    }

    leave()
  },
  mounted() {
    this.$nextTick(() => {
      this.startLoading();
      this.getAllData()
    })
  },
  watch: {
    loadingStatus: {
      handler(value) {
        if (this.loadingView == null) {
          return
        }
        if (value) {
          this.startLoading();
        } else {
          this.loadingView.close();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    startLoading:function() {
      let dom = document.getElementById('findDeviceList')
      if (typeof dom == 'undefined' || dom == null) {
        return;
      }
      this.loadingView = Loading.service({ target: dom })
    },
    showSearchView: function() {
      if (typeof this.$refs.searchInput == 'undefined') {
        return
      }
      this.$refs.searchInput.blur()
      this.showSearch = true
    },
    sure: function() {
      this.showSearch = false
      if (typeof this.$refs.searchView == 'undefined') {
        return
      }
      this.$refs.searchView.clearStore()
    },
    updateSelect: function(res) {
      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i]
        if (search.type == res.type) {
          search.selectList = res.nodes
          break
        }
      }
    },
    getAllData: function() {
      function updateDataList(searchList, type, res) {
        for (let i = 0; i < searchList.length; i++) {
          let search = searchList[i]
          if (search.type == type) {
            search.dataList = res.list
            search.selectList = res.selectList
            search.loading = false
            break
          }
        }
      }

      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i]
        search.loading = true
      }
      // this.getOrg().then(res => {
      //   updateDataList(this.searchList, 'org', res)
      // })

      this.getAssetCategory().then(res => {
        updateDataList(this.searchList, 'asset', res)
      })

      this.getModel().then(res => {
        updateDataList(this.searchList, 'model', res)
      })

      setTimeout(()=>{
        this.loadingStatus = false;
      }, 1000)
    },
    // 获取品牌型号
    getModel: function() {
      return new Promise(resolve => {
        let list = [{
          id: '',
          name: '全部型号',
          parentId: '',
          parentName: '',
          children: []
        }]
        let selectList = list
        resolve({ list: list, selectList: selectList })
      })
    },
    // 获取子机构科室
    getOrg: function() {
      return new Promise(resolve => {
        this.orgList = []
        this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
          let jsonStr = JSON.stringify(res.data)
          jsonStr = jsonStr.replace(/title/g, 'name')
          jsonStr = jsonStr.replace(/child/g, 'children')

          let list = []
          let selectList = []

          try {
            list = JSON.parse(jsonStr)
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < list.length; i++) {
              let org = list[i]
              if (org.id == tenantId) {
                selectList.push(org)
                break
              }
            }
          } catch (e) {

          }
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    // 获取资产分类
    getAssetCategory: function() {
      return new Promise(resolve => {
        this.assetCategoryList = []
        this.$api.assetModule.getDeviceCategoryTree({}).then(res => {
          let list = [{
            id: '',
            name: '全部资产分类',
            parentId: '',
            parentName: '',
            children: res.data
          }]
          let selectList = list
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    currentChange: function(index) {
      this.page = index
      // this.findDevice()
    },
    findDevice: function() {
      function findQuery(item) {
        let query = ''
        let selectList = item.selectList
        for (let i = 0; i < selectList.length; i++) {
          let select = selectList[i]
          query = query + (query == '' ? '' : ',') + select.name
        }
        return query
      }

      this.deviceList = [];
      let query = ''
      if (typeof this.$refs.searchView != 'undefined') {
        query = this.$refs.searchView.getInput()
      }
      let classQuery = ''
      let trademarkQuery = ''
      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i]
        if (search.type == 'asset') {
          classQuery = findQuery(search)
        } else if (search.type == 'model') {
          trademarkQuery = findQuery(search)
        }
      }
      let config = {
        query: query,
        classQuery: classQuery,
        trademarkQuery: trademarkQuery,
        snQuery: '',
        assetIDQuery: '',
        rfidQuery: '',
        page: 1,
        pageSize: 30,
        nameQuery: ''
      }
      this.loadingStatus = true
      return new Promise(resolve => {
        this.$api.oldCAT.findDevice(config).then(res => {
          console.log(res)
          let list = []
          if (res.code == 200) {
            list = res.data
          }
          let totalCount = res.totalCount
          resolve({ list: list, totalCount: totalCount })
        }).catch(e => {
          resolve({ list: [], totalCount: 0 })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.findDeviceBGView {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #eeeeee;


  .findDeviceInPut {
    width: calc(100% - 20px);
    transform: translate(10px, 0px);
    padding-top: 10px;
  }

  .findDeviceMap {
    width: calc(100% - 20px);
    height: 30%;
    transform: translate(10px, 0px);
    margin-top: 10px;
    background-color: white;
  }


  .findDeviceList {
    width: 100%;
    margin-top: 10px;
    height: calc(70% - 110px);
    background-color: white;
    overflow: scroll;

    .findDeviceUl {
      width: 100%;
      list-style-type: none;
      padding: 0;

      .findDeviceLi {
        .findDeviceLiContent {
          width: 100%;
        }

        .title {
          margin-left: 10px;
          color: $color_primary;
          width: 80px;
          height: 30px;
          line-height: 30px;
          text-align: left;
          font-size: 15px;
        }

        .content {
          color: gray;
          width: calc(100% - 110px);
          margin-left: 10px;
          margin-right: 10px;
          line-height: 30px;
          text-align: left;
          font-size: 15px;
          word-wrap: break-word;
        }

        .line {
          background-color: $color_primary;
          width: calc(100% - 10px);
          height: 1px;
        }
      }
    }
  }

  .findDevicePage {
    width: 100%;
    height: 40px;
    background-color: white;
  }

}

</style>
