<template>
  <div class="mapView" id="map">

  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'mapView',
  data() {
    return {
      map: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  methods: {
    initMap() {
      let imageWidth = 2056

      let mapX = imageWidth / 2.0
      let mapY = imageWidth / 2.0

      let imageCenterX = 1222 / 2.0
      let imageCenterY = 728 / 2.0

      let scaleCountX = (mapX - imageCenterX) / imageWidth
      let scaleCountY = (mapY - imageCenterY) / imageWidth

      let x = scaleCountX * 360
      let y = 0 - scaleCountY * 180

      this.map = L.map('map', {
        center: [0, 0],
        zoom: 0, //缩放比列
        zoomControl: false,
        attributionControl: false,
        minZoom: 0,
        maxZoom: 4,
      })
      const tileLayer = L.tileLayer.wms('https://maps.kang-cloud.com/maps/7/{z}/{x}/{y}.png', {
        noWrap:true,
      })
      this.map.addLayer(tileLayer)

      this.map.panTo([x, y], {
        animate: true
      })

    }
  }
}
</script>

<style lang="scss" scoped>

.mapView {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

</style>
