<template>
  <div class="searchView">
    <div class="searchBGView">
      <el-input
          placeholder="请输入内容"
          v-model="inPut">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <div class="searchFillterContent">
        <div v-for="(item) in searchList" class="searchFillter">
          <el-button @click="showSelectTree(item)" :loading="item.loading" class="title" size="mini" type="text">
            {{ item.title }}
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" class="fillter">
            <div class="chooseSelectItem" v-for="(selectItem) in item.selectList">
              {{ selectItem.name }}
            </div>
          </h-stack>
        </div>
      </div>
      <h-stack class="searchAction" justify-content="flex-end">
        <el-button type="primary" @click="sure">确定</el-button>
      </h-stack>
    </div>

    <vue-tree
        @dialogClose="dialogClose"
        @dialogSure="dialogSure"
        :expanded-keys="defaultCheckedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :dialog-visible="showTree"
        :data-list="treeList"
        :dialog-title="treeTitle">
    </vue-tree>

  </div>
</template>

<script>
import VueTree from '@/components/tree/vueTree'
import HStack from '@/components/hStack/hStack'

export default {
  name: 'searchView',
  components: { HStack, VueTree },
  props: {
    searchList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    defaultCheckedKeys: function() {
      let list = []
      let selectList = []
      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i]
        if (search.type == this.treeType) {
          selectList = search.selectList
          break
        }
      }

      for (let i = 0; i < selectList.length; i++) {
        let asset = selectList[i]
        if (list.indexOf(asset.id) >= 0) {
          continue
        }
        list.push(asset.id)
      }
      return list
    },
    treeList: function() {
      let list = ''
      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i]
        if (search.type == this.treeType) {
          list = search.dataList
          break
        }
      }
      return list
    },
    treeTitle: function() {
      let title = ''
      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i]
        if (search.type == this.treeType) {
          title = search.title
          break
        }
      }
      return title
    }
  },
  watch: {
    searchList: {
      handler(value) {
        for (let i = 0; i < value.length; i++) {
          let v = value[i]
          if (i == 0) {
            this.treeType = v.type
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      inPut: '',
      showTree: false,
      treeType: '',
      storeOldSelect:{},
    }
  },
  methods: {
    getInput:function() {
      return this.inPut;
    },
    sure:function() {
      this.clearStore();
      this.$emit('sure', {});
    },
    resetOldStore:function() {
      for (const type in this.storeOldSelect) {
        let list = this.storeOldSelect[type];
        for (let i = 0; i < this.searchList.length; i++) {
          let search = this.searchList[i];
          if (type == this.treeType) {
            search.selectList = list;
            break;
          }
        }
      }
      this.storeOldSelect = {};
    },
    clearStore:function() {
      this.storeOldSelect = {};
      this.inPut = ''
    },
    dialogClose: function(res) {
      this.showTree = res.dialogVisible
    },
    dialogSure: function(res) {
      this.showTree = res.dialogVisible
      res.type = this.treeType
      for (let i = 0; i < this.searchList.length; i++) {
        let search = this.searchList[i];
        if (search.type == this.treeType) {
          this.storeOldSelect[search.type] = search.selectList;
          break;
        }
      }
      this.$emit('updateSelect', res)
    },
    showSelectTree: function(item) {
      this.treeType = item.type
      this.showTree = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.searchView {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;

  .searchBGView {
    width: calc(100% - 20px);
    transform: translate(10px, 0px);
    padding-top: 10px;
    height: 100%;

    .searchFillterContent {
      height: calc(100% - 100px);
      width: 100%;
      overflow: scroll;

      .searchFillter {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;


        .title {
          color: white;
          background-color: $color_primary;
          width: 80px;
          height: 30px;
        }

        .fillter {
          width: calc(100% - 90px);
          margin-left: 10px;
          float: right;

          .chooseSelectItem {
            padding-left: 5px;
            padding-right: 5px;
            word-wrap: break-word;
            max-width: 90%;
            margin-left: 5px;
            margin-bottom: 5px;
            min-height: 30px;
            font-size: 13px;
            color: $color_primary;
            border: $color_primary 1px solid;
            //background-color: $color_primary;
            line-height: 28px;
            border-radius: 5px;
          }
        }
      }
    }

    .searchAction {
      width: 100%;
      height: 50px;
    }

  }
}

</style>
